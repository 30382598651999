.redbutton {
  background-color: #ef090b !important;
  padding: 0.625rem 1.5rem !important;
  &.create-race {
    margin: 0 auto 1.5rem 1.5rem;
  }
}
.logo-sn {
  a {
    img {
      max-width: 80px !important;
    }
  }
}
.heading-title {
  color: #434349;
  font-size: 26px;
}
.form-group {
  position: relative;
  margin-bottom: 1.5rem !important;
  .invalid-feedback {
    bottom: -19px;
    text-align: left;
    padding-left: 20px;
    margin-top: 0;
  }
  label {
    color: #666674;
  }
}
.registration-form {
  &.ms-form-group {
    &.has-icon {
      i {
        top: 0 !important;
      }
    }
  }
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  padding-top: 25px;
}
.pagination > .active > a {
  background-color: #ef090b;
  border-color: #ef090b;
  color: #fff !important;
  border-radius: 50%;
}
.pagination > li > a {
  border: 1px solid #dee2e6;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  margin-right: 0.425rem;
  display: block;
  line-height: 1.25;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #ef090b;
  border-color: #ef090b;
  outline: none;
  border-radius: 50%;
}
.pagination > li > a,
.pagination > li > span {
  color: #212529;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 50%;
}
// .custom-checkbox {
//   &::before {
//     content: "";
//     width: 18px;
//     height: 18px;
//     border-radius: 2px;
//     border: 0;
//     /* border-width: 2px; */
//     transition: all;
//     transition-duration: 0s;
//     transition-duration: 250ms;
//     background: #e5e3f1;
//   }
//   &::after {
//     content: "\e5ca";
//     transition: all 0s;
//     -webkit-transition: all 250ms;
//     transition-duration: 250ms;
//     font-family: "Material Icons";
//     font-weight: normal;
//     font-style: normal;
//     font-weight: 700;
//     opacity: 0;
//     font-size: 0.5625rem;
//     color: #fff;
//     text-align: center;
//     width: 18px;
//     line-height: 18px;
//   }
// }
.form-control {
  background: #f8f9fc !important;
  border: 0 !important;
}
.nav-tabs {
  &.tabs-round {
    .nav-item {
      .nav-link {
        border-radius: 5px 5px 0 0 !important;
      }
    }
  }
}
.tab-content {
  padding: 0 1.5rem 1.5rem;
}

.panel-title {
  color: #666674;
  font-size: 20px;
  text-transform: capitalize !important;
  font-weight: 400 !important;
}
.label-box {
  .form-control {
    width: 170px;
  }
}
.select-box {
  .location-box {
    margin-right: 12px;
    margin-bottom: 15px;
    position: relative;
    .invalid-feedback {
      left: 0;
      bottom: -18px;
      display: block;
      text-align: left;
      right: auto;
    }
  }
}
.alerticon {
  width: 20px;
  height: auto;
  border-radius: 0;
  margin-left: 5px;
}
.review-icon {
  width: 80px;
  height: 80px;
  img {
    width: 100%;
    height: 100%;
  }
}
.filter-search-box {
  width: 190px;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.custom-route-select {
  display: block;
  width: 170px;
  height: 35px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: #f8f9fc !important;
  border: 0 !important;
}
.custom-select {
  background-color: #f0f0f7 !important;
  border: 0 !important;
}
.custom-search-box {
  height: 40px;
}
.custom-file-label {
  border: 0 !important;
  /* width: auto; */
  // width: 120px;
  // width: 0;
  &::after {
    border: 0;
    color: #fff !important;
    background-color: #a1a1a1 !important;
    border-radius: 15px !important;
    left: 15px !important;
    right: auto !important;
    padding: 0.8rem 1.75rem !important;
    line-height: 0.6 !important;
  }
}
.show-preview {
  height: auto;
  width: 140px;
  margin-top: 10px;
  position: relative;
  img {
    width: 100%;
  }
  .closeicon {
    position: absolute;
    top: -4px;
    right: -5px;
    color: #515151;
    font-size: 17px;
  }
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f4f5f8 !important;
}
.footer-section {
  border-radius: 0;
  background: #fff;
  padding: 17px 30px;
  // height: 30px;
  position: absolute;
  bottom: 0;
  width: calc(100% - 253px);
  p {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 420px) {
    width: 100%;
  }
}

.edit-preview {
  height: 150px;
  width: 200px;
  position: relative;
  margin-top: 10px;
  img {
    width: 100%;
    height: 100%;
  }
  i {
    position: absolute;
    top: -3px;
    right: -5px;
    color: #232323;
    z-index: 1111;
  }
}
.preview {
  position: relative;
  display: inline-block;
  margin-top: 15px;
  i {
    position: absolute;
    top: -3px;
    right: -5px;
    color: #232323;
    z-index: 1111;
  }
}

.react-datepicker-wrapper {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;

  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background: #f8f9fc !important;
  border: 0 !important;
  input {
    border: 0;
    background: transparent;
  }
}

.color-picker-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > div {
    margin-right: 20px;
  }
}

.error-box {
  color: red;
  padding-left: 0;
  margin-bottom: 0.5rem;
}
@media only screen and (max-width: 420px) {
  .mobileView {
    display: none;
  }
}
